import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { useGetSurveyStructureQuery } from '@dimatech/features-survey/lib/api/survey/surveyApi';
import { Question, QuestionValue } from '@dimatech/features-survey/lib/models';
import { Select } from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { config } from 'config';
import { CSSProperties, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SelectOccupation = ({
  occupation,
  setOccupation,
  style,
}: {
  occupation?: number;
  setOccupation: (occupation: string) => void;
  style?: CSSProperties;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const [values, setValues] = useState<QuestionValue[]>();

  const { data: questions, isLoading: isLoadingQuestions } =
    useGetSurveyStructureQuery(
      accessToken.customerId
        ? {
            surveyId: config.survey?.id as string,
            _customerId: accessToken.customerId,
          }
        : skipToken
    );

  useEffect(() => {
    const question = questions?.find(
      (question: Question) => question.id === config.dikios.occupationQuestionId
    );
    if (question) {
      setValues(question.questionType.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions]);

  if (isLoadingQuestions) {
    return (
      <div style={{ margin: 10 }}>
        <LoaderSmall />
      </div>
    );
  }

  return (
    <>
      <Select
        value={occupation ?? ''}
        onChange={(e) => setOccupation(e.currentTarget.value)}
        style={style}
      >
        <option value="">{t('Filter.AllOccupations')}</option>
        {[...(values ?? [])]
          .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
          .map((value, index) => (
            <option key={index} value={value.value}>
              {t(`Survey.${config.survey?.id}.Option.${value.value}.Label`)}
            </option>
          ))}
      </Select>
    </>
  );
};

SelectOccupation.displayName = 'SelectOccupation';
