import { Administrators } from '@dimatech/features-core/lib/features/administrate-users/Administrators';
import { Viewers } from '@dimatech/features-core/lib/features/administrate-users/Viewers';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { useGetEntitiesWithSurveyQuery } from '@dimatech/features-survey/lib/api/entity/entityApi';
import { Tab, Tabs } from '@dimatech/shared/lib/components/tab';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

enum TabIndexes {
  CustomerAdmin = 0,
  Reader = 1,
}

export const AdministrateUsers = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const location = useLocation();
  const navigate = useNavigate();

  const [selectedTabIndex, setSelectedTabIndex] = useState(
    location.pathname.endsWith('reader')
      ? TabIndexes.Reader
      : TabIndexes.CustomerAdmin
  );

  const { data: entities, isLoading } = useGetEntitiesWithSurveyQuery(
    accessToken.customerId && accessToken.user?.id
      ? {
          _customerId: accessToken.customerId,
          _userId: accessToken.user?.id,
          surveyInstanceId: undefined,
        }
      : skipToken
  );

  const isViewersEnabledFlagOn =
    useFlags()[flags.permanent.app.dikios.isViewersEnabled];

  const handleSelectTab = (index: number) => {
    setSelectedTabIndex(index);

    navigate(
      `/administrate/users/${
        index === TabIndexes.Reader ? 'reader' : 'customer-admin'
      }`
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <ViewHeader>
        <Heading1>{t('Administrate.Users.Title')}</Heading1>
      </ViewHeader>

      <Tabs>
        <Tab
          isSelected={selectedTabIndex === TabIndexes.CustomerAdmin}
          handleSelect={() => handleSelectTab(TabIndexes.CustomerAdmin)}
        >
          {t('UserRoles.CustomerAdmin.Title')}
        </Tab>

        {isViewersEnabledFlagOn && (
          <Tab
            isSelected={selectedTabIndex === TabIndexes.Reader}
            handleSelect={() => handleSelectTab(TabIndexes.Reader)}
          >
            {t('UserRoles.Reader.Title')}
          </Tab>
        )}
      </Tabs>

      <Card width="100%">
        <CardBody>
          {selectedTabIndex === TabIndexes.CustomerAdmin && (
            <Administrators isLoading={isLoading} entities={entities} />
          )}

          {selectedTabIndex === TabIndexes.Reader && (
            <Viewers isLoading={isLoading} entities={entities} />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

AdministrateUsers.displayName = 'AdministrateUsers';
