import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { useGetSurveyInstances } from '@dimatech/features-survey/lib/hooks/useGetSurveyInstances';
import { AlertInfo, AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { LoaderFill } from '@dimatech/shared/lib/components/loader';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewRow,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  selectFilterAllCustomers,
  selectFilterByEntity,
} from 'api/dikiosSlice';
import {
  useGetCarelessQuery,
  useGetOverviewQuery,
  useGetProgressQuery,
} from 'api/statistics/statisticsApi';
import { SelectExtendedFilters } from 'components/SelectExtendedFilters';
import { ViewHeader } from 'components/ViewHeader';
import { useAppSelector } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { EntityType, FilterableEntityType } from 'models';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { OurResultByCategory } from './components/OurResultByCategory';
import { Statistics } from './components/Statistics';
import { StatisticsCareless } from './components/StatisticsCareless';
import { StatisticsDontKnow } from './components/StatisticsDontKnow';
import { StatisticsResponseRate } from './components/StatisticsResponseRate';

export const Overview = (): JSX.Element | null => {
  const { t } = useTranslation();
  const filterByEntity = useAppSelector(selectFilterByEntity);
  const filterAllCustomers = useAppSelector(selectFilterAllCustomers);
  const { accessToken } = useContext(AuthenticationContext);
  const customerId = accessToken.customerId;

  const isCarelessRespondentEnabledFlagOn =
    useFlags()[flags.permanent.app.dikios.isCarelessRespondentEnabled];

  let entityType = accessToken.user?.currentCustomer
    .customerType as FilterableEntityType;

  if (
    !(
      [
        EntityType.Municipality,
        EntityType.Government,
        EntityType.Region,
      ] as FilterableEntityType[]
    ).includes(entityType)
  ) {
    entityType = EntityType.Other;
  }

  const { instances, isSuccess: isSuccessInstances } =
    useGetSurveyInstances(true);

  const {
    data: overview,
    isFetching: isFetchingOverview,
    isLoading: isLoadingOverview,
  } = useGetOverviewQuery(
    filterByEntity.instance && customerId && accessToken.user?.id
      ? {
          filter: filterByEntity,
          _customerId: customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const {
    data: overviewNationally,
    isFetching: isFetchingOverviewNationally,
    isLoading: isLoadingOverviewNationally,
  } = useGetOverviewQuery(
    filterAllCustomers.instance && customerId && accessToken.user?.id
      ? {
          filter: {
            ...filterAllCustomers,
            entityType: accessToken.isInternational ? undefined : entityType,
          },
          _customerId: customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const {
    data: progress,
    isFetching: isFetchingProgress,
    isLoading: isLoadingProgress,
  } = useGetProgressQuery(
    filterByEntity.instance && customerId && accessToken.user?.id
      ? {
          filter: filterByEntity,
          _customerId: customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const {
    data: careless,
    isFetching: isFetchingCareless,
    isLoading: isLoadingCareless,
  } = useGetCarelessQuery(
    isCarelessRespondentEnabledFlagOn &&
      filterByEntity.instance &&
      customerId &&
      accessToken.user?.id
      ? {
          filter: filterByEntity,
          _customerId: customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const isLoading =
    isLoadingOverview || isLoadingProgress || isLoadingOverviewNationally;

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Overview.Title')}</Heading1>
        <SelectExtendedFilters
          isRespondentFilterShown={false}
          isCarelessFilterShown={isCarelessRespondentEnabledFlagOn}
        />
      </ViewHeader>

      {isLoading ? (
        <LoaderFill />
      ) : (
        <>
          {overview && overview.principles.length === 0 && (
            <Card width="100%">
              <AlertInfo>{t('Overview.NoData')}</AlertInfo>
            </Card>
          )}

          {(!instances || instances.length === 0) && isSuccessInstances && (
            <Card width="100%">
              <AlertWarning>{t('Overview.NoInstances')}</AlertWarning>
            </Card>
          )}

          {overview &&
            overview.principles.length > 0 &&
            instances &&
            instances.length > 0 && (
              <>
                <Card>
                  <CardBody isLoading={isFetchingOverview}>
                    <Statistics principles={overview.principles} />
                  </CardBody>
                </Card>
                <ViewRow style={{ width: '100%', alignItems: 'stretch' }}>
                  <Card
                    title={t('Overview.OurResultByCategory.Title')}
                    canBeSavedAsPng={true}
                  >
                    <CardBody
                      isLoading={
                        isFetchingOverview || isFetchingOverviewNationally
                      }
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <OurResultByCategory
                        principlesNationallyByCategory={
                          overviewNationally?.categories
                        }
                        principlesByCategory={overview.categories}
                        name={t('Overview.OurResultByCategory.Title')}
                      />
                    </CardBody>
                  </Card>

                  {isCarelessRespondentEnabledFlagOn && (
                    <Card
                      title={t('Overview.StatisticsCareless.Title')}
                      helpText={
                        <Trans i18nKey={'Overview.StatisticsCareless.Help'} />
                      }
                      canBeSavedAsPng={true}
                      style={{ minWidth: 280 }}
                    >
                      <CardBody
                        isLoading={isFetchingCareless || isLoadingCareless}
                      >
                        <StatisticsCareless careless={careless} />
                      </CardBody>
                    </Card>
                  )}
                </ViewRow>

                <Card title={t('Overview.StatisticsDontKnow.Title')}>
                  <CardBody isLoading={isFetchingOverview}>
                    <StatisticsDontKnow
                      principlesByCategory={overview.categories}
                    />
                  </CardBody>
                </Card>

                <Card title={t('Overview.StatisticsResponseRate.Title')}>
                  <CardBody isLoading={isFetchingProgress}>
                    <StatisticsResponseRate progress={progress} />
                  </CardBody>
                </Card>
              </>
            )}
        </>
      )}
    </>
  );
};

Overview.displayName = 'Overview';
