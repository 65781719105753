import { Chart } from '@dimatech/features-core/lib/components/Chart';
import { RadarChartAxisTick } from '@dimatech/features-core/lib/components/RadarChartAxisTick';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Theme } from '@dimatech/shared/lib/themes';
import { trackEvent } from '@dimatech/shared/lib/tracking';
import { ChartTooltipSplit } from 'components/ChartTooltipSplit';
import { EventType, PrinciplesByCategory } from 'models';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  RadarChart,
  Radar as RadarChartRadar,
  Tooltip,
} from 'recharts';
import { withTheme } from 'styled-components';
import { formatAsPercent } from 'utils';

export const OurResultByCategory = withTheme(
  ({
    theme,
    principlesByCategory,
    principlesNationallyByCategory,
    name,
  }: {
    theme: Theme;
    principlesByCategory: PrinciplesByCategory;
    principlesNationallyByCategory?: PrinciplesByCategory;
    name: string;
  }): JSX.Element => {
    const { t } = useTranslation();
    const { accessToken } = useContext(AuthenticationContext);

    const data = [
      {
        key: t(`CommonTerms.${principlesByCategory.structure.key}`),
        percentage: principlesByCategory.structure.percentage,
        percentageNationally:
          principlesNationallyByCategory?.structure?.percentage,
      },
      {
        key: t(`CommonTerms.${principlesByCategory.technology.key}`),
        percentage: principlesByCategory.technology.percentage,
        percentageNationally:
          principlesNationallyByCategory?.technology?.percentage,
      },
      {
        key: t(`CommonTerms.${principlesByCategory.culture.key}`),
        percentage: principlesByCategory.culture.percentage,
        percentageNationally:
          principlesNationallyByCategory?.culture?.percentage,
      },
      {
        key: t(`CommonTerms.${principlesByCategory.interaction.key}`),
        percentage: principlesByCategory.interaction.percentage,
        percentageNationally:
          principlesNationallyByCategory?.interaction?.percentage,
      },
      {
        key: t(`CommonTerms.${principlesByCategory.motivation.key}`),
        percentage: principlesByCategory.motivation.percentage,
        percentageNationally:
          principlesNationallyByCategory?.motivation?.percentage,
      },
    ];

    const nationalAverageLabel = accessToken.isInternational
      ? t('CommonTerms.SwedishAverage')
      : t('CommonTerms.NationalAverage');

    const keys = [
      { key: t('CommonTerms.OurResult') },
      { key: nationalAverageLabel },
    ];

    const [indicators, setIndicators] = useState(
      keys.reduce((a: Record<string, boolean>, { key }) => {
        a[key] = false;
        return a;
      }, {})
    );

    const handleSwitch = (e: string) => {
      setIndicators({
        ...indicators,
        [e]: !indicators[e],
      });

      trackEvent(EventType.ChartLegendClick, {
        name,
      });
    };

    return (
      <Chart>
        <RadarChart
          style={{ height: '105%' }}
          cx="50%"
          cy="50%"
          outerRadius="85%"
          data={data}
        >
          <PolarGrid stroke={theme.colors.chart.gridStroke} strokeWidth={0.5} />
          <PolarAngleAxis
            dataKey="key"
            strokeWidth={0.5}
            stroke={theme.colors.chart.axisStroke}
            tickLine={false}
            tick={({ x, y, payload }) => (
              <RadarChartAxisTick x={x} y={y} payload={payload} />
            )}
          />

          <PolarRadiusAxis
            angle={90}
            domain={[0, 100]}
            tickCount={5}
            strokeWidth={0.5}
            tickLine={false}
            tickFormatter={(value) => formatAsPercent(value, '0')}
            stroke={theme.colors.chart.axisStroke}
          />

          <RadarChartRadar
            name={t('CommonTerms.OurResult')}
            dataKey="percentage"
            stroke={theme.colors.chart.primary}
            fill={theme.colors.chart.primary}
            fillOpacity={0.3}
            hide={indicators[t('CommonTerms.OurResult')] === true}
          />

          <RadarChartRadar
            name={nationalAverageLabel}
            dataKey="percentageNationally"
            stroke={theme.colors.chart.secondary}
            fill={theme.colors.chart.secondary}
            fillOpacity={0.3}
            hide={indicators[nationalAverageLabel] === true}
          />

          <Legend
            verticalAlign="bottom"
            iconType="circle"
            onClick={(e) => handleSwitch(e.value)}
            formatter={(value: string) => (
              <span style={{ cursor: 'pointer' }}>{value}</span>
            )}
          />

          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            content={<ChartTooltipSplit />}
          />
        </RadarChart>
      </Chart>
    );
  }
);

OurResultByCategory.displayName = 'OurResultByCategory';
